import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FieldValues, UseFormRegister, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import P from '@ui/atoms/texts/base/P';
import UILoginForm from '@ui/organisms/forms/login/LoginForm';

import { authAtom } from '@/services/store/store';
import { getEnv } from '@/utils/importMeta';
import getSubDomain from '@/utils/getSubDomain';

import { URLS } from '@/auth/constants';
import { AuthTokensProps } from '@/auth/pages/login/types';
import LinkButton from '@/components/link/LinkButton';
import { buildWebappUrl } from '@/utils/router';

type LoginFormProps = {
  onSubmit: (args: AuthTokensProps) => void;
  onProSanteConnect?: () => void;
  error?: string;
  creation?: boolean;
  subtitle?: string;
  loading?: boolean;
  proSanteConnectLoading?: boolean;
};


const authorizedSignup = getEnv('VITE_SIGNUP').split(';');

const isSignUpActive = () => {
  const subDomain = getSubDomain();

  return authorizedSignup.includes(subDomain);
};

const LoginForm: FC<LoginFormProps> = ({
  onSubmit,
  error,
  creation,
  subtitle,
  proSanteConnectLoading,
  onProSanteConnect,
  loading
}) => {
  const history = useHistory();
  const me = useRecoilValue(authAtom);
  const { t } = useTranslation('auth');
  const { register, handleSubmit } = useForm<AuthTokensProps>({
    defaultValues: {
      username: me?.username,
      // when me?.username exists = user check rememberMe at the last login
      // so we also check the box
      rememberMe: !!me?.username
    }
  });

  const onForgot = useMemo(() =>
    () => history.push(URLS.FORGOT),
  [history]
  );

  return (
    <UILoginForm
      error={error}
      subtitle={subtitle}
      onForgot={onForgot}
      onProSanteConnect={onProSanteConnect}
      proSanteConnectLoading={proSanteConnectLoading}
      loading={loading}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      onSubmit={handleSubmit(onSubmit)}
      register={register as unknown as UseFormRegister<FieldValues>}
    >
      {isSignUpActive() && creation
        ? (
          <div className='flex flex-col'>
            <P className='mb-5 font-medium'>{t('form.login.action.no_account')}</P>

            <LinkButton
              href={buildWebappUrl(URLS.CREATE)}
              data-testid='loginForm-action-create'
              color='light'
            >
              {t('form.login.action.create')}
            </LinkButton>
          </div>
        ) : null
      }
    </UILoginForm>
  );
};

export default LoginForm;
