import { useRecoilState } from 'recoil';
import { ConstantItemKeyType, constantsAtom, ConstantsItems } from '@/services/store/store';
import useJsonAPIRequest from '@/services/request/useJsonAPIRequest';
import { buildUrl } from '@/utils/Api';
import { useCallback, useEffect } from 'react';
import { ConstantItem } from '@/components/form/fields/constant-selector';

let isRowLoading = false;

type getConstantsType = {
  entity : ConstantItemKeyType,
  property : string
}

export type getConstantType = (
  entity : ConstantItemKeyType,
  property : string,
  value : string | undefined | number | null
) => string | number | null;

const useApiConstant = () => {

  const [{ isLoading, constants }, setConstants] = useRecoilState(constantsAtom);

  const { fetchData } = useJsonAPIRequest<ConstantsItems>({
    'url': buildUrl('constants'),
    method: 'get',
    skip: true
  });

  const reload = useCallback(() => {

    isRowLoading = true;

    fetchData({},{ throw: true }).then((data) => {
      setConstants({
        isLoading: false,
        constants: data as ConstantsItems
      });

      isRowLoading = false;
    }).catch(() => {
      isRowLoading = false;
      setConstants({
        isLoading: false,
        constants: null
      });
    });

  },[fetchData,setConstants]);

  useEffect(() => {

    if (constants || isRowLoading || isLoading) {
      return;
    }

    reload();

    setConstants((prevValue) => {
      return {
        ...prevValue,
        isLoading: true,
      };
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, constants]);


  const getConstants = useCallback(({ entity, property } : getConstantsType) : ConstantItem | null => {

    if(!constants){
      return null;
    }

    if(!constants[entity]) {
      throw new Error(`The entity ${entity} does not exist in the constants`);
    }

    if(!constants[entity].properties[property]) {
      throw new Error(`The property ${property} does not exist in the entity ${entity}`);
    }

    return constants[entity].properties[property];


  },[constants]);

  const getConstant = useCallback((entity,property,value) : string | number | null => {

    const item = getConstants({ entity,property });

    if(!item){
      return null;
    }

    return item.data[value];

  },[getConstants]);


  return {
    constants,
    isLoading,
    reload: fetchData,
    getConstants,
    getConstant
  };
};

export default useApiConstant;