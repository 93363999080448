import React, { FC } from 'react';
import { RouteProps, Redirect } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { useLocation } from 'react-router-dom';
import { authAtom } from '@/services/store/store';
import { URLS } from '@/auth/constants';
import { ROLE } from '@/packages/types';
import Route from '@/components/router/Route';
import RedirectToLogin from '@/utils/RedirectToLogin';
import { getEnv } from '@/utils/importMeta';
import P from '@ui/atoms/texts/base/P';
import * as Sentry from '@sentry/react';

type PrivateRouteProps = RouteProps & {
  roles?: ROLE[]
};

const PrivateRoute: FC<PrivateRouteProps> = ({ roles, ...rest }) => {
  const me = useRecoilValue(authAtom);

  const location = useLocation();

  if (!me?.isConnected) {

    let pathIGetFromTheRedirect =
      location.pathname && location.pathname !== '/' ? '/v2' + location.pathname : undefined;

    if(location.search && pathIGetFromTheRedirect) {
      pathIGetFromTheRedirect += encodeURIComponent(location.search);
    }

    return <RedirectToLogin path={pathIGetFromTheRedirect} />;
  }
  
  Sentry.setUser({
    email: me.user?.attributes.email,
    id: me.user?.attributes._id?.toString(),
    username: me.user?.attributes.email,
  });

  Sentry.setTag('role', me.user?.attributes.role);

  if (roles && (!me?.user?.attributes?.role || roles.indexOf(me.user.attributes.role) === -1)) {

    return getEnv('VITE_ENV') === 'development' ?
      (<P>Access Denied for user</P>) :
      (<Redirect to={URLS.LOGIN} />);

  }


  return (<Route {...rest} />

  );


};

export default PrivateRoute;
