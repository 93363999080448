import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

/**
 * A custom hook that builds on useLocation to parse
 * the query string for you.
 **/
const useURLParams = <T>(args: string[]): T => {
  const { search } = useLocation();

  return useMemo(
    () => {
      const searchParams = new URLSearchParams(search);

      return args.reduce(
        (o, k) => ({ ...o, [k]: searchParams.get(k) }),
        {}
      ) as T;
    },
    [search, args]
  );
};

export default useURLParams;
