import React, { FC, useEffect } from 'react';
import { RouteProps } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { authAtom } from '@/services/store/store';

import useDispatch from '@/auth/hooks/useDispatch';
import Route from '@/components/router/Route';

const AuthFormRoute: FC<RouteProps> = (props) => {
  const me = useRecoilValue(authAtom);

  const { dispatch } = useDispatch();

  useEffect(() => {

    if (!me?.isConnected) {
      return;
    }

    dispatch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [me]);



  if (!me?.isConnected) {
    return <Route {...props} />;
  }

  return null;

};

export default AuthFormRoute;
