import useRequest, { Response, UseRequestProps } from '@/services/request/useRequest';
import { Collection, Entity, Item, Relationship } from '@/packages/back-end/jsonapi';
import { useMemo } from 'react';
import { getUrlUnion } from '@/utils/Api';

type JsonApiDataType = unknown & {
  include?: string[]
}

export type UseJsonApiRequestProps = UseRequestProps & {
  include?: string[];
  abortable?: boolean;
}


const attachRelationshipsToItem =
  <T extends Entity>(includedMap: Map<string, Entity>, entity: T): T => {


    const relationships = entity.relationships;
    if (!relationships) {
      return entity;
    }

    // Iterate over all relationship keys
    Object.keys(relationships).forEach(key => {
      const relationshipData = relationships[key].data as Relationship | Relationship[];
      if (Array.isArray(relationshipData)) {
      // Handle collections
        relationshipData.forEach((item, index) => {
          if (includedMap.has(item.id)) {
            relationshipData[index] =
            attachRelationshipsToItem(includedMap, includedMap.get(item.id) as Entity);
          }
        });
      } else if (relationshipData && includedMap.has(relationshipData.id)) {
      // Handle single items
        relationships[key].data =
        attachRelationshipsToItem(includedMap, includedMap.get(relationshipData.id) as Entity);
      }
    });

    return entity;
  };

export const attachRelationships = <T extends Entity>(data: Collection<T>|Item<T>): Collection<T>|Item<T> => {
  if (!data.included) {
    return data;
  }

  // Create a map and a set to track processed entities
  const includedMap = new Map<string, Entity>();
  data.included.forEach(entity => {
    includedMap.set(entity.id, entity);
  });

  const processData = (entity: T) => attachRelationshipsToItem(includedMap, entity);

  if (Array.isArray(data.data)) {
    data.data = data.data.map(processData);
  } else {
    data.data = processData(data.data);
  }

  return data;
};


type JsonApiResponseType = Collection|Item;

const useJsonAPIRequest = <ResponseType, DataType = unknown>({
  url,
  skip, // not launch the query on mount
  method = 'get',
  data = {} as JsonApiDataType,
  headers = {},
  unauthenticated,
  include = [],
  abortable = true
}: UseJsonApiRequestProps): Response<DataType, ResponseType> => {

  headers.Accept = 'application/vnd.api+json';

  if(include.length > 0) {

    if (method?.toLowerCase() === 'get') {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      data.include = include?.join(',');
    } else {
      const separator = getUrlUnion(url);
      url = `${url}${separator}include=${include?.join(',')}`;
    }

  }

  const { data: responseData,...rest } = useRequest({ url, skip, method, data, headers, unauthenticated, abortable });

  const linked : JsonApiResponseType|undefined = useMemo(() => {

    if(!responseData) {
      return undefined;
    }

    return attachRelationships(responseData as JsonApiResponseType) as JsonApiResponseType;

  }, [responseData]);


  return {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    data: linked,
    ...rest
  };

};

export default useJsonAPIRequest;
