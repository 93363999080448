import React, { FC, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useResetRecoilState } from 'recoil';

import useRequest from '@/services/request/useRequest';
import storage, { StorageKeys } from '@/services/request/helpers/storage';
import { MeState } from '@/services/store/store';

import useURLParams from '@/utils/hooks/useURLParams';
import RedirectToLogin, { getLoginUrl } from '@/utils/RedirectToLogin';

const LogoutActions = () => {
  const resetMeState = useResetRecoilState(MeState);

  const { path } = useURLParams(['path']);

  const history = useHistory();

  const { fetchData: apiLogout } = useRequest({
    url: '/api/v2/logout',
    method: 'delete',
    skip: true
  });

  const { fetchData: logout } = useRequest({
    url: '/logout',
    skip: true
  });


  useEffect(() => {
    apiLogout()
      .then(
        () => logout()
      ).then(
        () => {
          storage.clearAll();
          resetMeState();
          history.push(getLoginUrl(path));
        }
      );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

const Logout:FC = () => {

  const token = storage.getItem(StorageKeys.TOKEN);

  if (!token) {
    return <RedirectToLogin />;
  }

  return <LogoutActions />;
};

export default Logout;
