import React from 'react';
import Modal from '@ui/molecules/modal/Modal';
import { useTranslation } from 'react-i18next';
import PasswordField from 'instamed-styleguide/ui/atoms/fields/password/PasswordField';
import Button from 'instamed-styleguide/ui/atoms/buttons/button/Button';
import storage, { StorageKeys } from '@/services/request/helpers/storage';
import useRequest from '@/services/request/useRequest';
import { buildUrl } from '@/utils/Api';
import { useForm } from 'react-hook-form';
import { ResponseData } from '../form/UpdateForm';
import useLogIn from '@/auth/hooks/useLogIn';

type RefreshResponse = {
  token: string;
};

type RefreshDataType = {
  refresh_token: string | null;
  password: string | null;
};

const AuthModal = ({
  isOpen,
  closeModal,
}: {
  isOpen: boolean;
  closeModal: () => void;
}) => {
  const { t } = useTranslation('auth', { keyPrefix: 'auth_refresh_modal' });
  const { register, handleSubmit } = useForm();
  const refreshToken = storage.getItem(StorageKeys.REFRESH_TOKEN);
  const { legacyLogIn } = useLogIn();

  const { loading, fetchData, error } = useRequest<
    RefreshResponse,
    RefreshDataType
  >({
    skip: true,
    method: 'POST',
    url: buildUrl('token/refresh'),
  });

  const handleDeconnectUser = () => {
    closeModal();
    storage.clearAll();
    window.location.reload();
  };

  const handleReConnectUser = async (data: ResponseData) => {
    if (!data?.password) {
      return;
    }
    storage.removeItem(StorageKeys.TOKEN);

    try {
      const response = await fetchData({
        password: data?.password,
        refresh_token: refreshToken
      }, { throw: true }) as RefreshResponse;
      storage.setJWT(StorageKeys.TOKEN, response.token);

      // Clear constants to avoid problem between users / role
      storage.removeItem(StorageKeys.CONSTANTS);
      storage.removeItem(StorageKeys.FEATURES);

      await legacyLogIn();
      closeModal();
    } catch (_e) {
      // Do nothing
    }
  };

  if (!isOpen) {
    return <></>;
  }

  return (
    <Modal
      title={t('modal_title')}
      onDismiss={closeModal}
      onSubmit={handleSubmit(handleReConnectUser)}
      footer={
        <div className='flex'>
          <Button type='button' className='mr-3'
            disabled={loading} onClick={handleDeconnectUser}>
            <span>{t('logout')}</span>
          </Button>
          <Button
            type='submit'
            color='primary'
            saving={loading}
          >
            <span>{t('refresh_login')}</span>
          </Button>
        </div>
      }
    >
      <div style={{ marginBottom: 10 }}>
        <span>{t('modal_subtitle')}</span>
      </div>
      <PasswordField
        label={t('password')}
        placeholder={t('password')}
        error={error?.message}
        {...register('password', {
          required: true
        })}
      />
    </Modal>
  );
};

export default AuthModal;
