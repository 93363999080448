import { useReducer, useCallback, useEffect, useRef, useState } from 'react';
import { useResetRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import isEqual from 'lodash/isEqual';
import { AxiosRequestConfig, AxiosResponseHeaders } from 'axios';

import request, { Method, RequestError } from '@/services/request/request';
import { authAtom } from '@/services/store/store';

import { isJwtSimultaneousLogin, isUnauthenticated } from './utils';
import { addUrlParameters } from '@/utils/Api';
import useToast from '@/utils/hooks/useToast';
import storage, { StorageKeys } from './helpers/storage';
import useRequestError from '@/utils/hooks/useRequestError';

export enum STATUS_CODE {
  BAD_REQUEST = 400,
  CONFLICT = 409,
  PAYMENT_REQUIRED = 402,
  PRECONDITION_REQUIRED = 428,
}

export type { Method, RequestError };

export type UseRequestProps<DataType = unknown> = {
  url: string;
  method?: Method;
  skip?: boolean;
  data?: DataType;
  headers?: Record<string, string>;
  unauthenticated?: boolean;
  abortable?: boolean;
};

export type FetchDataType<Data, Response> = (
  data?: Data,
  opts?: FetchOpts
) => Promise<RequestReturn<Response>>;

export type State<ResponseType> = {
  loading: boolean;
  data?: ResponseType;
  headers?: AxiosResponseHeaders;
  error?: RequestError;
  called?: boolean;
};

export type ResponseError = { error: RequestError };
export type RequestReturn<T> = T | ResponseError | undefined;

export type FetchOpts = {
  throw?: boolean;
  url?: string;
  method?: Method;
  displayToastOnError?: boolean;
  responseType?: AxiosRequestConfig['responseType'];
};

export type Response<Data, Response, S = State<Response>> = S & {
  fetchData: FetchDataType<Data, Response>;
  abort: () => void;
};

export const isRequestError = <T>(
  result: RequestReturn<T>
): result is ResponseError => {
  return !!result && Object.keys(result).includes('error');
};

enum REDUCER_ACTIONS {
  LOADING = 'loading',
  SUCCESS = 'success',
  ERROR = 'error',
}

export type Reducer<R, S = State<R>> = (
  state: S,
  action: Omit<S, 'loading'> & { type: REDUCER_ACTIONS, headers?: AxiosResponseHeaders }
) => S;

const createReducer =
  <ResponseType>(): Reducer<ResponseType> =>
    (state, action) => {
      const { data, headers, error } = action;
      switch (action.type) {
        case REDUCER_ACTIONS.LOADING:
          return { ...state, loading: true, error: undefined };
        case REDUCER_ACTIONS.SUCCESS:
          return { loading: false, data,headers, called: true };
        case REDUCER_ACTIONS.ERROR:
          return { ...state, loading: false,headers, error, called: true };
      }
    };

/**
 * hooks who handle services request
 */
const useRequest = <ResponseType = unknown, DataType = unknown>({
  url,
  skip, // not launch the query on mount
  method = 'get',
  data: dataProp,
  headers: headersProp = {},
  unauthenticated,
  abortable = true,
}: UseRequestProps<DataType>): Response<DataType, ResponseType> => {
  const { t, ready: tReady } = useTranslation('auth', {
    keyPrefix: 'form.error',
  });
  const resetAuthInfos = useResetRecoilState(authAtom);

  const { openModal } = useRequestError();
  const { addToast } = useToast();

  const [state, dispatch] = useReducer(createReducer<ResponseType>(), {
    loading: !skip,
  });

  const keepRef = useRef({
    abortControllers: [] as AbortController[],
    headers: headersProp,
    data: dataProp,
  });

  const [data, setData] = useState(dataProp);
  const [headers, setHeaders] = useState(headersProp);

  const abort = useCallback(() => {
    keepRef.current.abortControllers.forEach((abortController) => {
      abortController.abort();
    });
    keepRef.current.abortControllers = [];
  }, []);

  const fetchData = useCallback(
    (args?: DataType, opts?: FetchOpts) => {
      abort();

      const abortController = new AbortController();
      if (abortable) {
        keepRef.current.abortControllers.push(abortController);
      }

      dispatch({ type: REDUCER_ACTIONS.LOADING });

      const realMethod = opts?.method || method;
      let requestUrl: string = opts?.url || url;

      if (realMethod?.toLowerCase() === 'get') {
        requestUrl = addUrlParameters(requestUrl, {
          ...args,
          ...data,
        } as Record<string, unknown>);
      }

      return request<ResponseType>({
        method: realMethod,
        url: requestUrl,
        signal: abortController.signal,
        data: args instanceof FormData ? args : { ...data, ...args },
        headers,
        responseType: opts?.responseType,
      })
        .then(({ data, headers }) => {
          dispatch({ type: REDUCER_ACTIONS.SUCCESS, data, headers });
          return data;
        })
        .catch((error: RequestError) => {
          error.message = t(error.message, error.message);
          error.resolveUrl = error.response?.data.resolveUrl;
          error.productType = error.response?.data.productType;

          if (
            !abortController.signal.aborted ||
            keepRef.current.abortControllers.length === 0
          ) {
            dispatch({ type: REDUCER_ACTIONS.ERROR, error,headers: error.response?.headers });
          }

          const isUserUnauthenticated =
            !unauthenticated && isUnauthenticated(error?.code);
          const refreshToken = storage.getItem(StorageKeys.REFRESH_TOKEN);

          if (
            isUserUnauthenticated &&
            !isJwtSimultaneousLogin(error?.errorKey)
          ) {
            resetAuthInfos();
          } else if (
            isUserUnauthenticated &&
            isJwtSimultaneousLogin(error?.errorKey) &&
            requestUrl?.indexOf('/api/v2/users/me') === -1 &&
            refreshToken
          ) {
            openModal('auth');
          }

          if(error.code === STATUS_CODE.PAYMENT_REQUIRED) {
            openModal('payment',error.resolveUrl,error.productType);
          }

          if (opts?.displayToastOnError) {
            addToast('danger', error.message);
          }

          if (opts?.throw) {
            throw error;
          }

          return { error };
        });
    },
    [
      method,
      addToast,
      abort,
      url,
      abortable,
      data,
      headers,
      t,
      unauthenticated,
      resetAuthInfos,
      openModal,
    ]
  );

  useEffect(() => {
    if (!isEqual(keepRef.current.data, dataProp)) {
      keepRef.current.data = dataProp;
      setData(dataProp);
    }
  }, [dataProp]);

  useEffect(() => {
    if (!isEqual(keepRef.current.headers, headersProp)) {
      keepRef.current.headers = headersProp;
      setHeaders(headersProp);
    }
  }, [headersProp]);

  useEffect(() => {
    if (tReady && !skip) {
      fetchData();
    }
  }, [tReady, skip, fetchData]);

  return {
    ...state,
    abort,
    fetchData,
  };
};

export default useRequest;

export const getStatus = (
  loading: boolean,
  error: RequestError | undefined
): string => {
  let status = 'checked';

  if (error) {
    status = 'error';
  }

  if (loading) {
    status = 'loading';
  }

  return status;
};
