import { Entity } from '@/packages/back-end/jsonapi';


export enum CONTEXT {
  LOGIN = 'login',
  SIGNUP = 'signup',
  LINK = 'link',
  REFRESH = 'refresh'
}

type ProSanteConnectProfile = Entity & {

  attributes: {
    context: CONTEXT,
    tokenValid: string,
    tokenExpiryDate : string,
    authUrl: string,
    loggedIn: boolean,
  }

}

export default ProSanteConnectProfile;