import axios, { AxiosRequestConfig, Method } from 'axios';

import { URL_LEGACY,URL_DATA } from '@/constants';
import storage, { StorageKeys } from '@/services/request/helpers/storage';

import { errorParser, isJwtSimultaneousLogin, isUnauthenticated, isWrongPassword, RequestError } from './utils';

export type { Method, RequestError };

const axiosConfig = {
  withCredentials: true,
  baseURL: URL_LEGACY,
  headers: {
    'Content-Type': 'application/json',
    'X-Origin': 'webapp'
  }
};

const instance = axios.create(axiosConfig);

const isApiRequest = (url: string | undefined) => {

  if(!url) {
    return;
  }

  // TODO Add more check if you call a 3rd party API
  return !url.includes(URL_DATA);
};

instance.interceptors.request.use(
  (config: AxiosRequestConfig) => {

    const token = storage.getItem(StorageKeys.TOKEN) ?? undefined;

    if (!config.headers) {
      config.headers = {};
    }

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    const impersonationId = storage.getItem(StorageKeys.IMPERSONATION_KEY);

    if (impersonationId && isApiRequest(config.url)) {
      config.headers['X-Switch-User'] = impersonationId;
    }

    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);

export type NoContentType = {
  no_content: true
}

instance.interceptors.response.use(
  res => {
    if (res?.status === 204) {
      // add a content for convenient use in front
      // TODO clearly not the best ways to do it...
      // But sometimes we'll have to keep down and see how we can use form efficiently
      res.data = { no_content: true };
    }

    return res;
  },
  async err => {

    const parsedError = errorParser(err);

    if (isUnauthenticated(parsedError.code)) {
      if (
        !isJwtSimultaneousLogin(parsedError.errorKey) &&
        !isWrongPassword(parsedError.errorKey)
      ) {
        storage.removeItem(StorageKeys.TOKEN);
        storage.removeItem(StorageKeys.IMPERSONATION_KEY);
        storage.removeItem(StorageKeys.SYNAPSE_TOKEN);
        storage.removeItem(StorageKeys.MERCURE_TOKEN);
        storage.removeItem(StorageKeys.REFRESH_TOKEN);
        storage.removeItem(StorageKeys.CONSTANTS);
        storage.removeItem(StorageKeys.FEATURES);
      }
    }

    return Promise.reject(parsedError);
  }
);

export { instance };

export default instance.request;
