import LandingPage from '@/components/landing-page/LandingPage';
import getAssetURL from '@/utils/getAssetURL';
import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useRequest from '@/services/request/useRequest';
import { buildUrl } from '@/utils/Api';

import useURLParams from '@/utils/hooks/useURLParams';
import useLogIn from '@/auth/hooks/useLogIn';
import storage, { StorageKeys } from '@/services/request/helpers/storage';
import { storeAuthInfos } from '@/auth/hooks/useLogIn.utils';
import RedirectToLogin from '@/utils/RedirectToLogin';
import useToast from '@/utils/hooks/useToast';
import { buildWebappUrl } from '@/utils/router';
import LinkButton from '@/components/link/LinkButton';
import useDispatch from '@/auth/hooks/useDispatch';
import { NoContentType } from '@/services/request/request';

type AuthType = {
  token : string,
  refresh_token : string,
  refresh_token_expiry : number;
}

const AuthCallback : FC<{refresh ?: boolean}> = ({ refresh = false }) => {
  
  const { fetchData: save,error } = useRequest<AuthType|NoContentType|null>({
    url: buildUrl('auth', 'pro_sante_connect'),
    skip: true,
    method: 'POST',
    unauthenticated: false,
  });

  const { addToast } = useToast();

  const { dispatch } = useDispatch();

  const { legacyLogIn } = useLogIn();

  const { t } = useTranslation('auth', { keyPrefix: 'pro_sante_connect.login' });

  const { code, state } = useURLParams(['code', 'state']);

  useEffect(() => {

    if(!code || !state) {
      return;
    }

    (async () => {

      try {

        if(!refresh) {
          storage.removeItem(StorageKeys.TOKEN);
          storage.removeItem(StorageKeys.IMPERSONATION_KEY);
          storage.removeItem(StorageKeys.SYNAPSE_TOKEN);
          storage.removeItem(StorageKeys.CONSTANTS);
          storage.removeItem(StorageKeys.FEATURES);
        }


        const result = await save({
          code: code,
          state: state
        }, {
          throw: true,
          displayToastOnError: true
        }) as AuthType|null;


        if(null !== result && 'token' in result) {
          storeAuthInfos({
            token: result.token,
            refreshToken: result.refresh_token,
            refreshTokenExpiry: result.refresh_token_expiry
          });

          addToast('success',t('success'));
          legacyLogIn();
        } else {
          addToast('success', t('success'));
        }

        if(refresh) {
          dispatch();
        }

      } catch (_e) {
        // Do nothing the toasts are already displayed
      }
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code,t,refresh,addToast, state]);



  if(!code || !state) {
    return <RedirectToLogin />;
  }


  return (
    <LandingPage
      title={t('title')}
      subtitle={t('subtitle')}
      image={getAssetURL('/assets/images/auto-login.svg')}
    >
      {error && (<LinkButton
        className='mt-4'
        href={buildWebappUrl('/auth/login')}
      >
        {t('return_to_home')}
      </LinkButton>)}
    </LandingPage>
  );
};


export default AuthCallback;
