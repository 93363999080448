import { ReactNode, useCallback, useEffect, useState } from 'react';
import { ColorType } from '@ui/types';


type ListenerType = (toasts: ToastType[]) => void;

let globalToasts: ToastType[] = [];
let listeners: ListenerType[] = [];

export type addToastType = (color: ColorType, message: ReactNode, countdown ?: number, footer ?: ReactNode) => void;

type ToastType = {
  color: ColorType,
  key: string,
  footer?: ReactNode
  children: ReactNode,
}


const useToast = (
  shouldListen = false
): {
  toasts: ToastType[],
  addToast: addToastType,
  onDismiss: (key: string) => void
} => {

  const setToasts = useState<ToastType[]>(globalToasts)[1];

  const onDismiss = useCallback((key: string) => {

    const index : number = globalToasts.findIndex((elem) => elem.key === key);

    if(index === -1) {
      return;
    }

    globalToasts = globalToasts.slice(1);

    for (const listener of listeners) {
      listener(globalToasts);
    }


  }, []);


  useEffect(() => {
    if (shouldListen) {
      listeners.push(setToasts);
    }

    return () => {
      if (shouldListen) {
        listeners = listeners.filter(li => li !== setToasts);
      }
    };
  }, [setToasts, shouldListen]);


  const addToast: addToastType = useCallback((color, message, countdown ,footer) => {

    countdown = typeof countdown === 'undefined' ? footer ? 10000 : 5000 : countdown;

    const toast: ToastType = {
      color,
      footer,
      children: message,
      key: Math.random().toString(),
    };

    if (countdown) {
      setTimeout(() => {

        onDismiss(toast.key);
      }, countdown);
    }

    globalToasts = [...globalToasts, toast];

    for (const listener of listeners) {
      listener(globalToasts);
    }
  },
  [onDismiss]
  );


  return {
    toasts: globalToasts,
    addToast,
    onDismiss
  };

};

export default useToast;
