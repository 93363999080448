// eslint-disable-next-line
// @ts-nocheck

const bugfix = () => {

  // See https://github.com/facebook/react/issues/11538#issuecomment-417504600
  // And https://issues.chromium.org/issues/41407169
  if (typeof Node === 'function' && Node.prototype) {
    const originalRemoveChild = Node.prototype.removeChild;
    Node.prototype.removeChild = function(child) {
      if (child.parentNode !== this) {
        if (console) {
          // eslint-disable-next-line no-console
          console.warn('Cannot remove a child from a different parent', child, this);
        }
        return child;
      }
      // eslint-disable-next-line prefer-rest-params
      return originalRemoveChild.apply(this, arguments);
    };

    const originalInsertBefore = Node.prototype.insertBefore;
    Node.prototype.insertBefore = function(newNode, referenceNode) {
      if (referenceNode && referenceNode.parentNode !== this) {
        if (console) {
          // eslint-disable-next-line no-console
          console.warn('Cannot insert before a reference node from a different parent', referenceNode, this);
        }
        return newNode;
      }
      // eslint-disable-next-line prefer-rest-params
      return originalInsertBefore.apply(this, arguments);
    };
  }

};

export default bugfix;