export const ROUTES = {
  medical_staff: {
    subscriptions: '/subscriptions',
    dashboard: '/medical_staff/dashboard',
    patients: '/medical_staff/patients',
    patient_shared: '/medical_staff/patients_shared',
    send_files: '/medical_staff/send_files',
    medical_record_fields: '/medical_staff/medical_record_fields',
    statistics: '/medical_staff/statistics',
    profile: '/medical_staff/profile',
    cerfa_templates: {
      list: '/medical_staff/document_templates',
      update: '/medical_staff/cerfa_templates/:id',
    }
  }
};
