import UserType from '@/utils/sharing/types/user';


/**
 * Generate a hash code based of string id user
 */
export const hashCode: (str: string) => number = (str) => {
  let hash = 0;
  if (str) {
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
  }
  return Math.abs(hash);
};

/**
 * Extract initial
 */
export const extractInitial: (word1: string, word2?: string) => string = (word1, word2) => {
  return word1.charAt(0).toUpperCase() + ( word2
    ? word2.charAt(0).toUpperCase()
    : word1.charAt(1).toUpperCase()
  );
};

export type AvatarsReturnType = {
  alt: string,
  initial: string,
  colorKey: number
}

/**
 * Return avatar props for user
 */
export const getAvatarProps = ({ user, colorLength } : {
  user: UserType,
  colorLength: number
}): AvatarsReturnType => {

  const {
    // used to handle JsonLd & JsonAPI
    _id,
    id,
    firstName,
    fullName,
    fullLastName,
    aliasName
  } = user || {};

  let initial = '';
  if(firstName && fullLastName) {
    initial = extractInitial(firstName, fullLastName);
  } else if(aliasName || fullName) {
    initial = extractInitial(aliasName || fullName);
  }

  const joinName = firstName && fullLastName
    ? [firstName, fullLastName].join(' ')
    : null;

  return {
    alt: fullName || joinName || aliasName || initial,
    initial,
    colorKey: hashCode(`${_id ?? id}`) % colorLength
  };
};
