// For debug purposes only
import { darken } from '@mui/material/';
import PropTypes from 'prop-types';
import { ForwardRef } from 'react-is';
import jsx from 'react/jsx-runtime';
import Popper from '@mui/material/Popper/Popper';
import hoist from 'hoist-non-react-statics';
import clsx from 'clsx';
import tmx from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import * as fr from 'dayjs/locale/fr';


// This actually is useful
import { getEnv } from '@/utils/importMeta';
import React, { FC, lazy } from 'react';
import { Redirect, Switch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';

import Theme from '@ui/theming/styled/ThemeProvider';

import './App.scss';

import Tracking from '@/services/tracking/Tracking';
import { authAtom } from '@/services/store/store';
import useMe from '@/utils/hooks/useMe';

import Auth from '@/auth/Auth';
import PrivateRoute from '@/auth/core/private-route/PrivateRoute';
import Route from '@/components/router/Route';
import PageLoader from '@ui/atoms/loaders/page-loader/PageLoader';
import PatientBilling from '@/patient-billing/PatientBilling';
import { install } from 'resize-observer';

if (!window.ResizeObserver) {install();}
const AppStructure = lazy(() => import('@/app/structure/app/App'));
const ThrowError = lazy(() => import('@/components/throw-error/ThrowError'));
const HealthCheck = lazy(() => import('@/components/health-check/HealthCheck'));
const PublicAppStructure = lazy(() => import('@/app/structure/public-app/PublicApp'));

/* eslint-disable no-console */
// Leave this here because it breaks the CI-CD for cypress testing
const env = getEnv('VITE_ENV');
if(env === 'development' || env === 'test') {
  console.log(darken);
  console.log(PropTypes);
  console.log(ForwardRef);
  console.log(jsx);
  console.log(Popper);
  console.log(hoist);
  console.log(clsx);
  console.log(tmx);
  console.log(utc);
  console.log(customParseFormat);
  console.log(dayjs);
  console.log(isBetween);
  console.log(localizedFormat);
  console.log(weekOfYear);
  console.log(fr);
  console.clear();
}
const App: FC = () => {
  const { ready } = useTranslation();
  // check if connected
  // we try to load useMe for complete store with user information
  const { loading } = useMe();
  // then we wait to see the result in the store
  const { isLoading } = useRecoilValue(authAtom);

  if (!ready || loading || isLoading) {
    // display nothing until translation available
    return <PageLoader />;
  }

  return (
    <Theme>
      <Tracking />
      <Switch>
        <Route path='/auth'><Auth /></Route>
        <Route path='/patient_billing'><PatientBilling /></Route>
        <Route path='/public'><PublicAppStructure /></Route>
        <Route path='/__test__error'><ThrowError /></Route>
        <Route path='/health-check'><HealthCheck /></Route>
        <PrivateRoute path=''><AppStructure /></PrivateRoute>
        <Redirect to='/auth' from='*' />
      </Switch>
    </Theme>
  );
};

export default App;
