import User, { MeUser } from '@/packages/back-end/user';
import i18n from '@/i18n';
import { useCallback } from 'react';
import useApiConstant from '@/utils/hooks/useApiConstant';

const useSetLocale = () => {

  const { reload: resetConstants } = useApiConstant();

  const setLocale = useCallback((locale : string | undefined | null | User | MeUser) => {

    if(!locale) {
      return;
    }

    locale = typeof locale === 'string' ? locale : locale.attributes.locale;


    if(!locale || locale === i18n.language) {
      return;
    }

    i18n.changeLanguage(locale as string);
    resetConstants();


  },[resetConstants]);


  return {
    setLocale
  };


};


export default useSetLocale;