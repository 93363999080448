import React, { FC } from 'react';
import { Route as BaseRoute, RouteProps } from 'react-router-dom';
import Suspense from '@/components/router/Suspense';


const Route: FC<RouteProps> = ({ children, ...props }) => {

  return (<BaseRoute {...props} >
    <Suspense>
      {children}
    </Suspense>
  </BaseRoute>);
};


export default Route;