import { instance } from '@/services/request/request';
import storage, { StorageKeys } from '@/services/request/helpers/storage';

export type AuthInfos = {
  token: string;
  refreshToken: string;
  refreshTokenExpiry?: number;
  username?: string;
};

export type StoreAuthInfos = (
  args: { rememberMe?: boolean; } & AuthInfos
) => void;

export const storeAuthInfos: StoreAuthInfos = (args) => {
  storage.saveItems({
    [StorageKeys.REFRESH_TOKEN]: { value: args.refreshToken as string, expiry: args.refreshTokenExpiry as number },
    [StorageKeys.USERNAME]: args.rememberMe ? args.username : undefined,
  });

  storage.setJWT(StorageKeys.TOKEN, args.token);

};

export const getAuthInfos = () => ({
  token: storage.getItem(StorageKeys.TOKEN),
  [StorageKeys.REFRESH_TOKEN]: storage.getItem(StorageKeys.REFRESH_TOKEN),
  [StorageKeys.USERNAME]: storage.getItem(StorageKeys.USERNAME)
});

/**
 * Make log in to legacy instamed
 * TODO test this shit
 */
export const legacyLogInAction = (token: string): Promise<void> => {
  const formData = new FormData();
  formData.append('data[token]', token);

  return instance.request({
    url: '/api/user/refresh',
    method: 'post',
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
};


type ParsedJwt = {
  [key : string] : unknown
}

export function getJwtExpiry(jwt : string,defaultValue ?: Date) : Date {

  const decodedPayload = parseJwt(jwt);

  if(!defaultValue) {
    defaultValue = new Date();
    defaultValue.setDate(defaultValue.getDate() + 1);
  }

  // Extract the exp claim
  const exp = decodedPayload.exp;
  if (!(typeof exp === 'number')) {
    return defaultValue;
  }

  // Convert the exp to a Date object
  return new Date(exp * 1000);
}



function parseJwt(token : string) : ParsedJwt {
  try {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
    
    return JSON.parse(jsonPayload);
  } catch {
    return {};
  }
}
