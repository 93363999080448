import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { IntercomProvider } from 'react-use-intercom';
import { RecoilRoot } from 'recoil';

import '@ui/theming/theme.default.css';

import { getEnv } from '@/utils/importMeta';
import monitoring from '@/services/monitoring/monitoring';
import ErrorBoundary from '@/app/core/error-boundary/ErrorBoundary';

import './i18n';
import App from './App';
import RequestErrorModalHandler from '@/components/request-error-modal-handler/RequestErrorModalHandler';
import bugfix from '@/services/bugfix/bugfix';

bugfix();
monitoring();

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary showDialog>
      <IntercomProvider appId={getEnv('VITE_INTERCOM_APP_ID')}>
        <BrowserRouter basename={getEnv('VITE_BASE_URL')}>
          <RecoilRoot>
            <App />
            <RequestErrorModalHandler />
          </RecoilRoot>
        </BrowserRouter>
      </IntercomProvider>
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root')
);
