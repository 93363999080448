import React, { FC, lazy } from 'react';
import {
  withRouter,
  Switch,
  RouteComponentProps
} from 'react-router-dom';

import PrivateRoute from '@/auth/core/private-route/PrivateRoute';
const AuthCallback = lazy(() => import('@/patient-billing/pages/auth-callback/AuthCallback'));

const PatientBilling: FC<RouteComponentProps> = ({ match }) => {
  return (
    <Switch>
      <PrivateRoute path={`${match.path}/auth/callback`} exact >
        <AuthCallback />
      </PrivateRoute>
    </Switch>
  );
};

export default withRouter(PatientBilling);
