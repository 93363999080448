import UserType from '@/utils/sharing/types/user';

export enum ROLE {
  MEDECIN_PENDING = 'ROLE_MEDECIN_PENDING',
  MEDECIN = 'ROLE_MEDECIN',
  PARAMEDICAL = 'ROLE_INFIRMIERE',
  ASSISTANT = 'ROLE_ASSISTANT',
  PATIENT = 'ROLE_PATIENT',
  COORDINATOR = 'ROLE_MEDICAL_COORDINATOR',
  SUPPORT = 'ROLE_SUPPORT',
  HOSPITAL_ADMIN = 'ROLE_HOSPITAL_ADMIN',
  INSTITUTION_MANAGER = 'ROLE_INSTITUTION_MANAGER',
  ADMIN = 'ROLE_ADMIN',
  RESEARCH = 'ROLE_RESEARCH',
  DEVELOPER = 'ROLE_DEVELOPER'
}

export const MEDICAL_STAFF = [
  ROLE.MEDECIN,
  ROLE.PARAMEDICAL,
  ROLE.COORDINATOR
];

export const ADMINS = [
  ROLE.HOSPITAL_ADMIN,
  ROLE.INSTITUTION_MANAGER,
  ROLE.ADMIN
];

export const HOSPITAL_STAFF = [
  ROLE.MEDECIN,
  ROLE.PARAMEDICAL,
  ROLE.COORDINATOR,
  ROLE.HOSPITAL_ADMIN,
  ROLE.SUPPORT,
  ROLE.INSTITUTION_MANAGER,
  ROLE.ADMIN
];

export const ALL_ROLES = [
  ...HOSPITAL_STAFF,
  ROLE.PATIENT
];

export type Entity = {
  id: number | string
}

export type InstitutionType = Entity & {
  id: number;
  name: string;
  domain: string;
  logo: string;
  public: boolean;
  current: boolean;
  thumbnails?: {
    logo: {
      '30x30': string | null,
      '*x60': string | null,
      '*x70': string | null,
      '100x100': string | null
    }
  }
}

export type LegacyMe = UserType & {
  email: string;
  phones: { number: string }[];
  CGUAgreed: boolean;
  role: ROLE;
  institutions: InstitutionType[];
};

export enum PROFILE_STATUS {
  ENABLED = 1,
  DELETED = 3,
  TO_SUPERVISE = 4,
  INVALID = 5,
  INCOMPLETE = 6,
  BLACKLISTED = 7,
  DECEASED = 8,
  TO_REVIEW = 9,
  WAITING_IDENTITY_DOCUMENT = 10,
  TO_PAY = 31
}

type Specialty = {
  canonical: string
  id: string
  '@id': string
  name: string
};


export type Profile = Entity & {
  id: number;
  user: LegacyMe;
  RPPS: string;
  specialties: Specialty[];
  status: PROFILE_STATUS;
};

export type Task = Entity & {
  id: string;
  type: string;
  name: string;
};


export type Badges = {
  discussions: {
    ungrouped: number,
    grouped: number,
    archived: number,
    total: number,
    trash: number,
  },
  mssante: {
    total : number
  },
  events: {
    today: number,
  }
  notifications: {
    unread: number,
  },
  alerts: {
    total: number,
  },
  faqs: {
    unread: number
  },
  tips: {
    unread: number
  },
  answers_groups: {
    total: number,
    patients: number
  }
}
