import React, { FC } from 'react';
import cls from 'classnames';

import H1 from '@ui/atoms/texts/base/H1';

import getAssetURL from '@/utils/getAssetURL';
import Toasts from '@/components/toasts/Toasts';

export type LandingPageProps = React.HTMLAttributes<HTMLDivElement> & {
  title?: string;
  subtitle?: string;
  withLogo?: boolean;
  contentClassName?: string,
  imgClassName?: string,
  titleStyle?: string;
  image?: string;
};

export type LandingPageTextProps = React.HTMLAttributes<HTMLParagraphElement>;

export const LandingPageText: FC<LandingPageTextProps> = ({ children, className,...rest }) => (
  <p className={cls('text-base text-gray-800', className)} {...rest}>{children}</p>
);

const LandingPage: FC<LandingPageProps> = ({
  title,
  subtitle,
  image,
  children,
  withLogo = true,
  className,
  contentClassName,
  imgClassName,
  titleStyle,
  ...rest
}) => (
  <div
    className={cls('h-full overflow-auto pb-4 w-full', className)}
    {...rest}
  >
    {withLogo && (<div className='pl-5 m-9 sm:w-4/5'>
      <img
        src={getAssetURL('/assets/images/logo.svg')}
        alt='instamed'
        className='w-52'
      />
    </div>)}
    <div
      className={
        cls('flex flex-col h-full sm:pt-[100px] xl:pt-[0px] mt-12 md:justify-center items-center',
          'md:mt-18 w-full px-4 sm:px-0 sm:w-3/5 xl:w-2/5 mx-auto text-center', contentClassName
        )}>
      {image && <div
        className={cls('w-60 sm:w-80 h-32 sm:h-60 bg-center bg-no-repeat bg-contain',imgClassName)}
        style={{ backgroundImage: `url(${image})` }}
      />}
      {title && (<H1 className={`mt-5 sm:mt-10 mb-4 sm:mb-4 ${titleStyle ? titleStyle : ''}`}>{title}</H1>)}
      {subtitle && (<LandingPageText>{subtitle}</LandingPageText>)}
      {children}
    </div>
    <Toasts />
  </div>
);

export default LandingPage;
