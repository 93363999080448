import * as Sentry from '@sentry/browser';
import { getEnv } from '@/utils/importMeta';

const dsn = getEnv('VITE_SENTRY_DSN');
const environment = getEnv('VITE_ENV');

const monitoring = () => {
  Sentry.init({
    dsn,
    integrations: [
      Sentry.replayIntegration({
        blockAllMedia: false,
      }),
      Sentry.browserTracingIntegration()
    ],
    environment,
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.01,
    replaysOnErrorSampleRate: 1.0,
    ignoreErrors: [
      'No activity within 45000 milliseconds. 2 chars received. Reconnecting.',
      'ResizeObserver loop completed with undelivered notifications.',
      'Load failed',
      'Fetch is aborted'
    ]
  });
};

export default monitoring;
