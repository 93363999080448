import React, { FC } from 'react';
import useURLParams from '@/utils/hooks/useURLParams';
import { Redirect } from 'react-router-dom';
import { URLS } from '@/auth/constants';
import { addUrlParameters } from '@/utils/Api';


export function getLoginUrl(path : undefined | string) : string
{
  return path ? addUrlParameters(URLS.LOGIN, { path }) : URLS.LOGIN;

}

type RedirectToLoginProps = {
  path? : string
}

const RedirectToLogin : FC<RedirectToLoginProps> = ({ path: defaultPath }) => {

  const { path } = useURLParams(['path']);
  return <Redirect to={getLoginUrl(defaultPath ? defaultPath : path)} />;
};

export default RedirectToLogin;