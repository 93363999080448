import React from 'react';
import AuthModal from '@/components/auth-modal/AuthModal';
import PaymentModal from '@/components/payment-modal/PaymentModal';
import useRequestError from '@/utils/hooks/useRequestError';

const RequestErrorModalHandler = () => {

  const { closeModal,modalType,redirectUrl,productType } = useRequestError();

  return (
    <>
      <AuthModal isOpen={modalType === 'auth'} closeModal={closeModal} />
      <PaymentModal
        productType={productType}
        redirectUrl={redirectUrl}
        isOpen={modalType === 'payment'}
        closeModal={closeModal}
      />
    </>
  );
};

export default RequestErrorModalHandler;