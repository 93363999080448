export function getCookie(name: string): string | undefined {
  const value = `; ${document?.cookie || ''}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) {
    return parts.pop()?.split(';').shift();
  }
}

export function setCookie(name: string, value: string, days: number | Date): void {
  let expires = '';
  if (days) {

    let date;
    if(typeof days === 'number') {
      date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    } else {
      date = days;
    }
    expires = '; expires=' + date.toUTCString();
  }

  const domain = window.location.hostname.split('.').slice(-2).join('.');
  document.cookie = name + '=' + (value || '') + expires + '; path=/; domain=' + domain + ';secure';

}

export function removeCookie(name: string): void {
  setCookie(name, '', -1);
}