import React, { FC, useState, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Link from '@ui/atoms/texts/link/Link';
import UITwoFactorForm from '@ui/organisms/forms/two-factor/TwoFactorForm';
import UIRecoveryForm from '@ui/organisms/forms/two-factor-recovery/TwoFactorRecoveryForm';

import useRequest, { RequestError } from '@/services/request/useRequest';

import { AuthTokensProps, TwoFactorConfig } from '@/auth/pages/login/types';

const NEED_GENERATE = ['sms', 'email'];
const METHOD_RECOVERY = 'backup_code';

export type TwoFactorFormProps = TwoFactorConfig & {
  error?: string;
  setAuthTokens: (args: AuthTokensProps) => void;
};

type TwoFaCheckReturnType = {
    token: string;
    refresh_token: string;
    refresh_token_expiry: number;
}

/**
 * handle two factors and two factors recovery
 *
 */
const TwoFactorForm:FC<TwoFactorFormProps> = ({
  token,
  method,
  setAuthTokens,
  error
}) => {
  const [recovery, setRecovery] = useState<boolean>(false);
  const { register, handleSubmit } = useForm();
  const { t } = useTranslation('auth');

  const { loading, error: generateError } = useRequest({
    url: '/api/v2/2fa/otp/generate',
    method: 'post',
    data: { method },
    skip: NEED_GENERATE.indexOf(method) < 0,
    headers: {
      Authorization: `Bearer ${token}`
    }
  });

  const { fetchData,loading: saving, error: submitError } = useRequest<TwoFaCheckReturnType|RequestError>({
    url: '/api/v2/2fa/check',
    method: 'post',
    skip: true,
    data: { method },
    headers: {
      Authorization: `Bearer ${token}`
    }
  });

  const toggleRecovery = useCallback(() => setRecovery(v => !v), []);

  const onSubmit = useCallback(async ({ remember, ...args }) => {
    if (recovery) {
      args.method = METHOD_RECOVERY;
    }

    if (remember) {
      args.trust_device = remember;
    }
    
    const res = await fetchData(args,{
      throw: true
    }) as TwoFaCheckReturnType;

    setAuthTokens({
      token: res.token,
      refreshToken: res.refresh_token,
      refreshTokenExpiry: res.refresh_token_expiry
    });

  }, [fetchData, setAuthTokens, recovery]);

  const errorMsg = error || (submitError ? t('form.2fa.code.error') : undefined);

  if (recovery) {
    return (
      <UIRecoveryForm
        register={register}
        onSubmit={handleSubmit(onSubmit)}
        error={errorMsg}
        saving={saving}
      />
    );
  }

  return (
    <UITwoFactorForm
      register={register}
      onSubmit={handleSubmit(onSubmit)}
      error={errorMsg || generateError?.message}
      loading={loading}
      saving={saving}
      description={t(`form.2fa.method.${method}`)}
    >
      <Link
        onClick={toggleRecovery}
        className='ml-1 text-xs'
        data-testid='twofactor-recover-link'
      >
        {t('form.2fa.recovery')}
      </Link>
    </UITwoFactorForm>
  );
};

export default TwoFactorForm;
