import { useCallback } from 'react';

import useMe from '@/utils/hooks/useMe';
import useRequest, { RequestError } from '@/services/request/useRequest';
import { API } from '@/auth/constants';

import {
  storeAuthInfos,
  legacyLogInAction,
  getAuthInfos,
  AuthInfos
} from './useLogIn.utils';
import getSubDomain from '@/utils/getSubDomain';
import useDispatch from '@/auth/hooks/useDispatch';
import storage, { StorageKeys } from '@/services/request/helpers/storage';
import { Item } from '@/packages/back-end/jsonapi';
import { MeUser } from '@/packages/back-end/user';

export type LoginArgs = {
  username: string;
  password: string;
};

export type LoginResponse = {
  token: string;
  refresh_token: string;
};

export type UseLogin = () => {
  logIn: (
    args: LoginArgs,
    options?: { preventDispatch?: boolean }
  ) => Promise<MeUser>;
  loading: boolean;
  legacyLogIn: (args?: AuthInfos) => Promise<MeUser>;
  logged?: boolean;
  error?: RequestError;
};

const useLogIn: UseLogin = () => {
  const {
    loading,
    fetchData,
    data: logged,
    error
  } = useRequest<LoginResponse, LoginArgs>({
    url: API.LOGIN,
    skip: true,
    method: 'post',
    unauthenticated: true
  });

  const { dispatch, loading: dispatching } = useDispatch();

  const { fetchData: fetchMe, loading: fetchingMe } = useMe({
    skip: true
  });

  const legacyLogIn = useCallback(async (args) => {
    if (!args) {
      args = getAuthInfos();
    }

    await legacyLogInAction(args.token);

    storeAuthInfos(args);

    const meUserData = await fetchMe({}, { throw: true }) as Item<MeUser>;

    return meUserData.data;
  }, [fetchMe]);

  const logIn = useCallback(
    async (args, options) => {

      const institution = getSubDomain();

      // Never send the JWT key during login
      storage.removeItem(StorageKeys.TOKEN);
      storage.removeItem(StorageKeys.IMPERSONATION_KEY);
      storage.removeItem(StorageKeys.SYNAPSE_TOKEN);
      // Clear constants to avoid problem between users / role
      storage.removeItem(StorageKeys.CONSTANTS);
      storage.removeItem(StorageKeys.FEATURES);

      const result = await fetchData({ ...args,institution }, { throw: true });
      const {
        token,
        refresh_token: refreshToken
      } = result as LoginResponse;

      const newMeUser = await legacyLogIn({
        ...args,
        refreshToken,
        token
      });

      if (!options.preventDispatch) {
        dispatch();
      }

      return newMeUser;
    },
    [dispatch,fetchData, legacyLogIn]
  );

  return {
    logIn,
    loading: loading || fetchingMe || dispatching,
    legacyLogIn,
    logged: logged !== undefined,
    error
  };
};

export default useLogIn;

