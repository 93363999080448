import { FC, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import getSubDomain from '@/utils/getSubDomain';
import { getEnv } from '@/utils/importMeta';
import { MeState } from '@/services/store/store';
import amplitude from 'amplitude-js';
import { ROLE } from '@/packages/types';


const AMPLITUDE_API_KEY = getEnv('VITE_AMPLITUDE_API_KEY');

export const getAmplitudeRole = (role : ROLE) : string => {

  const mapping = {
    'ROLE_MEDECIN': 'doctor',
    'ROLE_MEDECIN_PENDING': 'doctor_pending',
    'ROLE_PATIENT': 'patient',
    'ROLE_INFIRMIERE': 'nurse',
    'ROLE_ADMIN': 'admin',
    'ROLE_HOSPITAL_ADMIN': 'hospital_admin',
    'ROLE_DEVELOPER': 'developer',
    'ROLE_MEDICAL_COORDINATOR': 'coordinator',
    'ROLE_INSTITUTION_MANAGER': 'institution_manager',
    'ROLE_SUPPORT': 'support',
    'ROLE_RESEARCH': 'research',
    // @deprecated
    'ROLE_ASSISTANT': 'assistant'
  };

  return mapping[role] || '';

};

const Tracking:FC = () => {
  const location = useLocation();
  const me = useRecoilValue(MeState);

  useEffect(() => {
    amplitude.getInstance().init(AMPLITUDE_API_KEY,me?.attributes?._id?.toString(),{
      apiEndpoint: 'api.eu.amplitude.com'
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {

    if(!me?.attributes?.role || !AMPLITUDE_API_KEY) {
      return;
    }

    amplitude.getInstance().setUserId(me?.attributes?._id?.toString() ?? null);
    amplitude.getInstance().setUserProperties({
      role: getAmplitudeRole(me.attributes.role),
      institution: getSubDomain(),
    });
  },[me]);

  useEffect(
    () => {

      if(!AMPLITUDE_API_KEY) {
        return;
      }

      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      let page = document.querySelector('title')!.innerText.trim();

      const options = document.querySelector('title')?.getAttribute('data-pii');
      let params : Record<string,string> = {};

      if(options && options !== 'undefined') {
        try {
          params = JSON.parse(options) as Record<string,string>;
        } catch (e) {
          // eslint-disable-next-line no-console
          console.warn(e);
        }
      }

      Object.keys(params).forEach((key) => {
        const value = params[key];

        page = page.replace(value,'*****');
      });

      amplitude.getInstance().logEvent('Page View', {
        page
      });
    },
    [location.pathname]
  );

  return null;
};

export default Tracking;
