import { AxiosError, AxiosResponse } from 'axios';
import get from 'lodash/get';
import { FieldValues, Path, UseFormSetError } from 'react-hook-form';
import { ProductType } from '@/services/store/store';

export type JsonLdResponseViolation = {
  propertyPath: string;
  message: string;
}

export type RequestViolations = {
  [key: string]: string;
}


export type RequestError = {
  code: number;
  error: string;
  message: string;
  errorKey?: string;
  field?: string;
  hasAccess?: boolean;
  object?: string;
  objectName?: string;
  resolveUrl?: string;
  productType?: ProductType;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  response?: AxiosResponse<any>
  violations?: RequestViolations
};



export type ConflictError = RequestError & {
  field: string;
  hasAccess: boolean;
  object: string | null;
  objectName: string | null;
};


type JsonLdViolationType = {
    propertyPath : string,
    message?: string
    title?: string
}


type JsonApiViolationType = {
    detail : string,
    source: {
      pointer : string
    }
}

const parseJsonLdViolations = (violations : JsonLdViolationType[]) => {

  const data : RequestViolations = {};

  violations.forEach(violation => {

    const message = violation.message ?? violation.title;
    if(message) {
      data[violation.propertyPath] = message;
    }
  });

  return data;

};

const parseJsonApiViolations = (violations : JsonApiViolationType[]) => {
  return parseJsonLdViolations(violations.map(parseJsonApiViolation));
};

const parseJsonApiViolation = (violation : JsonApiViolationType) : JsonLdResponseViolation => {

  const { detail: message,source } = violation;

  const propertyPath = source.pointer.split('/').pop()?.replace(/^_/,'') as string;

  return {
    propertyPath,
    message
  };

};



export function errorParser(err: AxiosError): RequestError | ConflictError {

  const error: RequestError = {
    code: parseInt(get(err, 'response.status', 500)),
    error: get(err, 'response.data.hydra:title', 'API not found'),
    message: get(err, 'response.data.hydra:description', 'api-error'),
    response: err.response,
    errorKey: get(err,'response.data.errorKey'),
    field: get(err,'response.data.field'),
    hasAccess: get(err,'response.data.hasAccess'),
    objectName: get(err,'response.data.objectName'),
    object: get(err,'response.data.object'),
  };

  let violations;
  if(error.response?.data?.violations) {
    violations = parseJsonLdViolations(error.response?.data?.violations);
  }
  if(error.response?.data?.errors) {
    violations = parseJsonApiViolations(error.response?.data?.errors);
  }

  if(!violations) {
    return error;

  }

  return { ...error, violations };
}

export function axiosErrorParser(
  err: unknown,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setError: UseFormSetError<any>,
  options?: {
    fullPath?: boolean;
    keyMap?: Record<string, string>;
  }
) {
  const { fullPath, keyMap } = options || {};

  const errorData = (err as AxiosError)?.response?.data;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const errors = (errorData?.errors || []) as {
    detail: string;
    source: { pointer: string };
  }[];

  errors.forEach((error) => {
    const pointer = error?.source?.pointer || '';
    if (error.detail && error.source.pointer) {
      let fieldKey = pointer.replace('data/attributes/', '');
      if (!fullPath) {
        fieldKey = fieldKey.split('.').slice(-1)[0];
      }
      if (fieldKey) {
        setError(keyMap?.[fieldKey] ?? fieldKey, { message: error.detail });
      }
    }
  });
}

export function isJwtSimultaneousLogin(errorKey ?: string) : boolean {
  return errorKey === 'auth.jwt_simultaneous_login' || errorKey === 'auth.token_missing_or_invalid';
}

export function isWrongPassword(errorKey ?: string) : boolean {
  return errorKey === 'auth.invalid_password';
}


export function isUnauthenticated(code: number): boolean {
  return code === 401;
}



export const attachErrorsToFields = <TFieldValues extends FieldValues = FieldValues>(
  setError : UseFormSetError<TFieldValues> ,
  responseError?: RequestError,
  mapping?: Record<string, string>
) => {

  if(!responseError || !responseError.violations) {
    return;
  }

  Object.keys(responseError.violations).map((errorKey) => {

    const key = mapping?.[errorKey] ?? errorKey;

    setError(key as Path<TFieldValues>,{
      type: 'server', message: responseError?.violations?.[errorKey] as string
    });

  });

};

