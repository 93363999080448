import React, { useEffect, useMemo, useState } from 'react';
import { URLS } from '@/auth/constants';
import TwoColumns from '@/auth/structure/two-columns/TwoColumns';
import Phone from '@/packages/back-end/phone';
import { ME, MeItem } from '@/packages/back-end/user';
import useJsonAPIRequest from '@/services/request/useJsonAPIRequest';
import { ResponseError } from '@/services/request/useRequest';
import { buildUrl } from '@/utils/Api';
import useToast from '@/utils/hooks/useToast';
import Button from 'instamed-styleguide/ui/atoms/buttons/button/Button';
import TextField from 'instamed-styleguide/ui/atoms/fields/text/TextField';
import H1 from 'instamed-styleguide/ui/atoms/texts/base/H1';
import P from 'instamed-styleguide/ui/atoms/texts/base/P';
import { useTranslation } from 'react-i18next';
import { useLocation, useHistory } from 'react-router-dom';

const CreatePatientVerifyPhone = () => {
  const { t } = useTranslation('auth');
  const location = useLocation();
  const history = useHistory();
  const [phone, setPhone] = useState<Phone | null>(null);
  const [code, setCode] = useState<string>('');

  const params = location.state as { token: string, email:string, password:string};
  const { addToast } = useToast();
  const [errDescription, setErrDescription] = useState<string | undefined>(
    undefined
  );
  const [timeBeforeSendNextCode, setTimeBeforeSendNextCode] = useState(0);

  const { data } = useJsonAPIRequest<MeItem>({
    include: ['services', 'institutions', 'phones'],
    headers: { Authorization: `Bearer ${params?.token}` },
    url: ME(),
  });

  const {
    loading,
    headers,
    fetchData: validatePhone,
  } = useJsonAPIRequest<ResponseError>({
    method: 'post',
    skip: true,
    headers: { Authorization: `Bearer ${params?.token}` },
    url: buildUrl(`phones/${phone?.attributes?._id}/send_validation`),
  });

  const { fetchData: verifyCode, loading: verifying } = useJsonAPIRequest<ResponseError>({
    method: 'post',
    skip: true,
    headers: { Authorization: `Bearer ${params?.token}` },
    url: buildUrl(`phones/${phone?.attributes?._id}/validate`),
  });

  useEffect(() => {
    if (timeBeforeSendNextCode > 0){
      const interval = setInterval(() => setTimeBeforeSendNextCode(timeBeforeSendNextCode - 1), 1000);

      return () => clearInterval(interval);
    }
  }, [timeBeforeSendNextCode]);

  useEffect(() => {

    if(headers?.['retry-after']) {
      setTimeBeforeSendNextCode(parseInt(headers?.['retry-after']));
    }

  }, [headers]);

  useEffect(() => {
    if (data) {
      setPhone(
        (data?.data?.relationships?.phones?.data as Phone[] | undefined)?.[0] ||
          null
      );
    }
  }, [data]);

  useEffect(() => {
    if (phone) {
      validatePhone({
        url: buildUrl(`phones/${phone?.attributes?._id}/send_validation`),
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }}, [phone, validatePhone]);

  const cancelBtn = useMemo(
    () => ({
      children: t('form.action.cancel'),
      onClick: () => history.push(URLS.LOGIN, { email: params.email, password: params.password }),
    }),
    [history, params.email, params.password, t]
  );

  const resendCode = () => {
    addToast('primary', t('form.create.patient.phone.code_resent'));
    validatePhone({
      url: buildUrl(`phones/${phone?.attributes?._id}/send_validation`),
    });
  };

  const submit = async () => {
    const response = await verifyCode({ code });
    if ((response as ResponseError)?.error) {
      setErrDescription(response?.error?.response?.data?.errors?.[0]?.detail);
    } else {
      history.push(URLS.CREATE_PATIENT_VALIDATE, { email: params.email, password: params.password });
    }

  };

  return (
    <TwoColumns footerRight={<></>} cancelBtn={cancelBtn}>
      <div
        className={
          'flex flex-col md:justify-center mx-6 mt-12 md:mt-0 w-full md:w-[70%] h-full'
        }
      >
        <div className={'lg:mx-auto xl:mx-1'}>
          <div className={'mx-auto'}>
            <H1 className='mt-2 mb-4 font-medium text-gray-900'>
              {t('form.create.patient.phone.title')}
            </H1>

            <P className='mb-3 text-xs text-gray-900'>
              {t('form.create.patient.phone.description', {
                phoneNumber: phone?.attributes.number,
              })}
            </P>
            <TextField
              label={t('form.create.patient.phone.code')}
              placeholder={t('form.create.patient.phone.verify_code')}
              type='number'
              className='mb-3'
              error={errDescription}
              data-testid='code-input'
              onChange={(e) => setCode(e.target.value)}
            />
            {timeBeforeSendNextCode > 0 ? 
              <P className='text-xs'>
                {t('form.create.patient.phone.resend_code_availability', { timeInSeconde: timeBeforeSendNextCode })}
              </P>
              : <button onClick={resendCode}>
                <span className='text-xs font-medium'>{t('form.create.patient.phone.resend_code')}</span>
              </button>}
            

            <Button
              onClick={submit}
              disabled={loading}
              saving={verifying}
              data-testid={phone !== null ? 'phone-loaded' : loading ? 'loading' : 'submit'}
              className='mt-8 w-full'
              type='submit'
              color='primary'
            >
              {t('form.create.patient.phone.continue')}
            </Button>
          </div>
        </div>
      </div>
    </TwoColumns>
  );
};

export default CreatePatientVerifyPhone;
