import React, { FC } from 'react';
import { ErrorBoundary as SentryBoundary } from '@sentry/react';

import LandingPage from '@/components/landing-page/LandingPage';

import getAssetURL from '@/utils/getAssetURL';
import { FallbackRender } from '@sentry/react/build/types-ts3.8/errorboundary';

export type ErrorBoundaryType = {
  showDialog?: boolean;
};

const Fallback : FallbackRender = () => (
  <LandingPage
    title={"Une erreur s'est produite"}
    subtitle='Merci de réessayer ultérieurement ou contacter notre service client sur contact@instamed.fr'
    image={getAssetURL('/assets/images/error.svg')}
    data-testid='error-boundary__fallback'
  />
);

const ErrorBoundary: FC<ErrorBoundaryType> = ({ children, showDialog }) => (
  <SentryBoundary
    showDialog={showDialog}
    fallback={Fallback}
  >
    {children}
  </SentryBoundary>
);

export default ErrorBoundary;