import ProSanteConnectProfile, { CONTEXT } from '@/packages/back-end/pro-sante-connect-profile';
import { useCallback } from 'react';
import { Item } from '@/packages/back-end/jsonapi';
import useJsonAPIRequest from '@/services/request/useJsonAPIRequest';
import { buildUrl } from '@/utils/Api';
import storage from '@/services/request/helpers/storage';


type useProSanteConnectProps = {
  context: CONTEXT
  redirectPath?: string,
  impersonated?: string | number,
  target?: '_self' | '_blank'
}

const useProSanteConnect = ({ context, redirectPath, impersonated, target = '_self' }: useProSanteConnectProps) => {

  const { fetchData, loading, data: profile } = useJsonAPIRequest<Item<ProSanteConnectProfile>>({
    url: buildUrl('pro_sante_connect/auth', 'profile', { impersonated }),
    method: 'post',
    skip: true
  });

  const { fetchData: getPscProfile, loading: loadingProfile } = useJsonAPIRequest<Item<ProSanteConnectProfile>>({
    url: buildUrl('pro_sante_connect/profiles', 'me', { impersonated }),
    method: 'get',
    skip: true,
  });

  const waitUntilProfileIsValid = useCallback((checkInterval = 3000) => {
    return new Promise<ProSanteConnectProfile>((resolve) => {
      const checkProfile = async () => {

        try {
          const response = await getPscProfile() as Item<ProSanteConnectProfile>;
          if (response.data.attributes.tokenValid) {

            resolve(response.data);
          } else {
            setTimeout(checkProfile, checkInterval);
          }
        } catch {
          // If an error occurs, try again after the specified interval
          setTimeout(checkProfile, checkInterval);
        }
      };

      // Start the first check immediately
      checkProfile();
    });
  }, [getPscProfile]);

  const onProSanteConnect = useCallback(async () => {

    if ([CONTEXT.LOGIN, CONTEXT.SIGNUP].includes(context)) {
      storage.clearAll();
    }

    const profile = await fetchData({
      context,
      redirectPath: redirectPath ?? target === '_blank' ? null : undefined
    }, {
      throw: true,
      displayToastOnError: true
    }) as Item<ProSanteConnectProfile>;


    if (profile.data.attributes.tokenValid) {
      const expiryDate = new Date(profile.data.attributes.tokenExpiryDate);
      const currentDate = new Date();
      const tenMinutes = 1000 * 60 * 10; // 10 minutes in milliseconds

      if ((expiryDate.getTime() - currentDate.getTime()) > tenMinutes) {
        return;
      }
    }

    if (target === '_self') {
      document.location.assign(profile.data.attributes.authUrl);
    } else {
      window.open(profile.data.attributes.authUrl);
    }

    if (target === '_blank' && context === CONTEXT.REFRESH) {
      return await waitUntilProfileIsValid();
    }

  }, [waitUntilProfileIsValid, context, redirectPath, target, fetchData]);


  return {
    onProSanteConnect,
    waitUntilProfileIsValid,
    profile,
    loading: loading || loadingProfile
  };

};

export default useProSanteConnect;