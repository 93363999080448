import { useHistory, RouteComponentProps as H } from 'react-router-dom';
import useRequest from '@/services/request/useRequest';
import { buildUrl } from '@/utils/Api';
import { useCallback, useEffect } from 'react';
import { buildLegacyUrl, redirectToLegacy } from '@/utils/Legacy';
import { redirectHome } from '@/auth/core/auth-form-route/utils';
import useURLParams from '@/utils/hooks/useURLParams';

type DispatchType = {
  url: string
}

const DISPATCH_URL = buildUrl('dispatch');


// from https://stackoverflow.com/questions/49342390/typescript-how-to-add-type-check-for-history-object-in-react
export const redirectToPath = (path: string, history: H['history'],newWindow = false) => {

  if (path?.startsWith('/v2/')) {

    const url = path.replace('/v2/', '/');

    history.push(url);
    return;
  }

  if(newWindow) {
    window.open(buildLegacyUrl(path));
    return;
  }
  redirectToLegacy(path);
};

const useDispatch = () => {

  const history = useHistory();

  const { path } = useURLParams(['path']);

  const { data: dispatchData, fetchData: getDispatchInfo,loading, error } = useRequest<DispatchType>({
    skip: true,
    url: DISPATCH_URL
  });


  const dispatch = useCallback(() => {

    if(path) {

      if(path === 'null') {
        window.close();
      }

      redirectToPath(decodeURIComponent(path), history);
      return;
    }

    getDispatchInfo();

  },[path,getDispatchInfo,history]);


  useEffect(() => {

    if (error) {
      redirectHome();
      return;
    }

    if (dispatchData?.url) {
      redirectToPath(dispatchData.url,history);
    }

  }, [history,dispatchData, error]);


  return {
    dispatch,
    loading
  };
};

export default useDispatch;