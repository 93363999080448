import { getEnv } from '@/utils/importMeta';

export const URL_LEGACY = getEnv('VITE_URL_LEGACY');
export const URL_DATA = getEnv('VITE_URL_DATA');
export const URL_MERCURE = getEnv('VITE_URL_MERCURE');
export const URL_HELP = getEnv('VITE_URL_HELP');
export const URL_HELP_PATIENT = getEnv('VITE_URL_HELP_PATIENT');
export const URL_STELLAIR = getEnv('VITE_URL_STELLAIR');

export const URLS = {
  ME_PROFILE: '/api/v2/profile/me',
  USER_TASKS: '/api/v2/users/%USERID%/tasks',
  AUDIT_TRAIL: '/api/v2/audit_trail/search',
  AUDIT_TRAIL_GET_FILTERS: '/api/v2/audit_trail/filters',
  AMPLITUDE: '/api/v2/amplitude/records',
};
