import useToast from '@/utils/hooks/useToast';
import cls from 'classnames';
import zindex from '@/utils/zindex';
import Toast from '@ui/atoms/toasts/Toast';
import React, { FC } from 'react';


const Toasts : FC = () => {

  const { toasts,onDismiss } = useToast(true);

  return (<div className={cls('flex flex-col fixed right-[45px] bottom-[15px]',zindex.toast)}>
    {toasts.map(({ key,children,...toast }) => (
      <Toast
        data-testid={`toast-${toast.color}`}
        key={key}
        className={'bg-white'}
        onDismiss={() => onDismiss(key)}
        {...toast}
      >
        {children}
      </Toast>
    ))}
  </div>);

};

export default Toasts;
