import React, { useCallback } from 'react';
import Modal from '@ui/molecules/modal/Modal';
import { useTranslation } from 'react-i18next';
import LinkButton from '@/components/link/LinkButton';
import { ProductType } from '@/services/store/store';
import { redirectToPath } from '@/auth/hooks/useDispatch';
import { useHistory } from 'react-router-dom';

const PaymentModal = ({
  isOpen,
  redirectUrl,
  closeModal,
  productType,
}: {
  isOpen: boolean;
  redirectUrl: string | undefined;
  productType: ProductType | undefined;
  closeModal: () => void;
}) => {
  const { t } = useTranslation('auth', { keyPrefix: 'auth_payment_modal' });

  const history = useHistory();

  const onClick = useCallback(() => {

    closeModal();
    if(redirectUrl) {
      redirectToPath(redirectUrl, history);
    }
  },[closeModal,history,redirectUrl]);

  if (!isOpen) {
    return <></>;
  }

  return (
    <Modal
      title={t(`${productType ?? 'core'}.modal_title`)}
      onDismiss={closeModal}
      footer={
        <LinkButton
          onClick={onClick}
          color='primary'
          className='w-full'
          href={redirectUrl}
        >
          {t(`${productType ?? 'core'}.modal_button`)}
        </LinkButton>
      }
    >
      <div style={{ marginBottom: 10 }}>
        <span>{t(`${productType ?? 'core'}.modal_subtitle`)}</span>
      </div>
    </Modal>
  );
};

export default PaymentModal;
